var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"943caae22c2c7ec5d9249add1fa8495e529dcfd4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// // This file configures the initialization of Sentry on the browser.
// // The config you add here will be used whenever a page is visited.
// // https://docs.sentry.io/platforms/javascript/guides/nextjs/
// import { env } from '@constants/env';
// import * as Sentry from '@sentry/nextjs';

// const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

// Sentry.init({
//     dsn: SENTRY_DSN,
//     // Adjust this value in production, or use tracesSampler for greater control
//     tracesSampleRate: 1.0,
//     // ...
//     // Note: if you want to override the automatic release value, do not set a
//     // `release` value here - use the environment variable `SENTRY_RELEASE`, so
//     // that it will also get attached to your source maps

//     // TODO [chammaaomar]: Increase this when we go to production
//     replaysSessionSampleRate: 0.2,
//     // If the entire session is not sampled, use the below sample rate to sample
//     // sessions when an error occurs.
//     replaysOnErrorSampleRate: 1.0,

//     integrations: [
//         Sentry.replayIntegration({
//             maskAllText: false,
//             maskAllInputs: false,
//             blockAllMedia: false,
//             // From Sentry's FAQ: """Session Replay does not capture canvas content.
//             // We will explore capturing canvas content in a privacy conscious way in future iterations."""
//             unblock: ['canvas'],
//         }),
//     ],
//     // based on the environment variables set up in firebase-hosting-[pull-request | merge].yml
//     environment: env,
// });

export {};
