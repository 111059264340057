import { type ReactNode } from 'react';

import { useFontSize } from '@hooks/fontSizeContext';
import { useTashkeel } from '@hooks/tashkeelContext';
import useTranslation from '@hooks/useTranslation';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Alert, Box, useTheme } from '@mui/material';
import { Divider, IconButton, Typography } from '@mui/material';
import { removeTashkeel } from '@utils/tashkeel';
// @ts-ignore this library doesn't have exported types, nor is it part of the definitely typed project
import ReadMoreAndLess from '@vendor/react-read-more-less';

import {
    HadithHeaderInfo,
    HadithHeaderInfoDetails,
    HadithHeaderInfoNumber,
    HadithTextContainer,
    HadithTitleContainer,
} from './HadithResultItem/styled';
import { getHadithSubtitle } from './helpers';
import parse, { type HTMLReactParserOptions } from 'html-react-parser';
import { type Ruling } from 'shared/interfaces/hadith';
import { colors } from 'theme';

export type BareBonesHadithProps = {
    hadithHtml: string;
    book: ReactNode;
    chapter: string;
    subChapter: string;
    type?: string;
    bookNameRenderFlag?: boolean;
    titleDivider?: boolean;
    takhreej?: string[];
    page?: number;
    volume?: number;
    hideHeaderInfoNumber?: boolean;
    headerText?: string;
    pageVolumePlacement?: 'top-left' | 'body';
    hadith_numbers: number[];
    hadithOptions: HTMLReactParserOptions;
    collapsable?: boolean;
    collapsed?: boolean;
    setCollapsed?: (x: boolean) => void;
    rulings?: Ruling[];
    // TODO remove. temporary for validation purposes
    hybridSource?: string;
    score?: string;
};

export default function BareBonesHadith({
    hadithHtml,
    book,
    chapter,
    subChapter,
    type,
    titleDivider = false,
    bookNameRenderFlag,
    page,
    volume,
    hideHeaderInfoNumber,
    headerText,
    hadith_numbers,
    hadithOptions,
    collapsable,
    collapsed,
    setCollapsed,
    takhreej,
    pageVolumePlacement = 'top-left',
    rulings,
    hybridSource,
    score,
}: BareBonesHadithProps) {
    const { t } = useTranslation('library');
    const { fontSize } = useFontSize();
    const { tashkeelEnabled } = useTashkeel();
    const theme = useTheme();

    // remove keywords span but keep its children, since we're no longer using it in rendering
    // and it's interfering with the auto-suggest highlighting
    const removeKeywordsSpanRegex = /<span class='keywords'>(.*?)<\/span>/g;

    hadithHtml = hadithHtml.replace(removeKeywordsSpanRegex, '$1');

    const headerDetailsContents: string[] = [
        type ? type : '',
        volume ? `${t('volume')}: ${volume}` : '',
        page ? `${t('page')}: ${page}` : '',
        score ?? '',
        hybridSource ?? '',
    ].filter((str) => str);

    const pageVolumeBodyContents =
        page && volume
            ? `${t('volume')}: ${volume} - ${t('page')}: ${page}`
            : page
            ? `${t('page')}: ${page}`
            : volume
            ? `${t('volume')}: ${volume}`
            : '';

    return (
        <>
            {
                <HadithHeaderInfo>
                    {!hideHeaderInfoNumber && hadith_numbers.length > 0 ? (
                        <HadithHeaderInfoNumber>
                            <Typography>{hadith_numbers.join(', ')}</Typography>
                        </HadithHeaderInfoNumber>
                    ) : (
                        <Box />
                    )}
                    {headerText && (
                        <Alert severity="info" sx={{ margin: 1 }}>
                            <span style={{ fontSize: 'small' }}>
                                {headerText}
                            </span>
                        </Alert>
                    )}
                    <HadithHeaderInfoDetails>
                        {pageVolumePlacement === 'top-left' &&
                            headerDetailsContents.map((content, i) => (
                                <Typography
                                    key={content}
                                    sx={{
                                        padding: '5px',
                                        borderLeft:
                                            i > 0
                                                ? `1px solid  ${theme.palette.divider}`
                                                : undefined,
                                        height: '100%',
                                        color: colors.primary,
                                    }}
                                >
                                    {content}
                                </Typography>
                            ))}
                    </HadithHeaderInfoDetails>
                </HadithHeaderInfo>
            }

            <HadithTitleContainer
                dir="rtl"
                // used for testing, not ideal
                className="playwrightTestHadithTitleContainer"
            >
                {book && (
                    <Typography
                        pt={bookNameRenderFlag ? 1 : 0}
                        fontSize={fontSize.title}
                    >
                        {book}
                    </Typography>
                )}
                {pageVolumePlacement === 'body' && pageVolumeBodyContents && (
                    <Typography
                        fontSize={fontSize.subtitle}
                        textAlign="justify"
                        lineHeight={'32px'}
                        pr={1}
                        color={colors.primary}
                    >
                        {pageVolumeBodyContents}
                    </Typography>
                )}
                {(chapter || subChapter) && (
                    <Typography
                        fontSize={fontSize.subtitle}
                        textAlign="justify"
                        lineHeight={'32px'}
                        pr={1}
                        color={colors.primary}
                        component="div"
                    >
                        {getHadithSubtitle(chapter, subChapter).length ===
                        200 ? (
                            getHadithSubtitle(chapter, subChapter)
                        ) : (
                            <ReadMoreAndLess
                                charLimit={200}
                                readMoreText={
                                    <IconButton>
                                        <ExpandCircleDownIcon
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </IconButton>
                                }
                                readLessText={
                                    <IconButton>
                                        <ExpandCircleDownIcon
                                            color="secondary"
                                            sx={{ rotate: '180deg' }}
                                        />
                                    </IconButton>
                                }
                            >
                                {getHadithSubtitle(chapter, subChapter)}
                            </ReadMoreAndLess>
                        )}
                    </Typography>
                )}
            </HadithTitleContainer>

            {titleDivider && <Divider />}

            <HadithTextContainer key={hadithHtml}>
                {collapsable &&
                    (collapsed ? t('show_hadith') : t('hide_hadith'))}
                {collapsable && (
                    <IconButton
                        onClick={() => setCollapsed && setCollapsed(!collapsed)}
                    >
                        <ExpandCircleDownIcon
                            color="secondary"
                            fontSize="small"
                            sx={{ rotate: collapsed ? '0deg' : '180deg' }}
                        />
                    </IconButton>
                )}
                <Typography
                    fontSize={fontSize.body}
                    lineHeight={'38px'}
                    sx={{
                        color: '#121212',
                        wordSpacing: '1px',
                        display: collapsed ? 'none' : 'block',
                    }}
                >
                    {tashkeelEnabled
                        ? parse(hadithHtml, hadithOptions)
                        : parse(removeTashkeel(hadithHtml), hadithOptions)}
                </Typography>
                {rulings?.map((ruling) => (
                    <>
                        <Typography
                            key={`${ruling.ruler}-ruling`}
                            variant="body1"
                        >
                            {ruling.ruler}: {ruling.ruling}
                        </Typography>
                        {ruling.book_name && (
                            <Typography
                                variant="body2"
                                key={`${ruling.ruler}-ref`}
                                my={1}
                            >
                                {ruling.book_name} ({ruling.page}/
                                {ruling.volume})
                            </Typography>
                        )}
                    </>
                ))}
                {takhreej && (
                    <Typography>
                        {takhreej.map((narration) => (
                            <p key={narration}>{narration}</p>
                        ))}
                    </Typography>
                )}
            </HadithTextContainer>
        </>
    );
}
