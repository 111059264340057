import { Translation } from './translationsEn';

export const translationsAr: Translation = {
    layout: {
        follow_us: 'تابعنا على',
        trial_version: 'إصدار تجريبي',
        navbar_cta_button: 'تواصل معنا',
        navbar_link_hadiths: 'الأحاديث',
        navbar_link_ai: 'الذكاء الإصطناعي',
        navbar_link_narrators: 'الرواة',
        navbar_link_references: 'المراجع',
        navbar_settings_references: 'إعدادات',
        navbar_settings_references_with_l: 'الإعدادات',
        navbar_link_who_we_are: 'من نحن',
        navbar_link_more: 'المزيد',
        navbar_link_contact: 'تواصل معنا',
        navbar_link_contact_short: 'تواصل',
        navbar_link_userguide: 'دليل المستخدم',
        copyright: 'جميع الحقوق محفوظة لمركز تميز © ',
        footer_link_1: 'الأحاديث',
        footer_link_2: 'من نحن',
        footer_link_3: 'المراجع',
        footer_link_4: 'تواصل معنا',
        english: 'الإنجليزية',
        arabic: 'العربية',
        search_more: 'ابحث عن باقي',
        appstore: 'حَمْل الآن التطبيق لأجهزة الآيفون',
        playstore: 'حَمْل الآن التطبيق لأجهزة للأندرويد',
    },
    library: {
        in_progress_roads_graph_feature:
            'هذه الخاصية ما زالت قيد التطوير وقد يكون هنالك نقص أو زيادة في بعض الأسانيد المعروضة.',
        in_progress_roads_hadith_feature:
            'هذه الخاصية ما زالت قيد التطوير وقد يكون هنالك نقص أو زيادة في بعض الطرق المعروضة.',
        in_progress_narrations_feature:
            'هذه الخاصية ما زالت قيد التطوير وقد يكون هنالك نقص أو زيادة في بعض المرويات المعروضة.',
        in_progress_explainations_feature:
            'هذه الخاصية ما زالت قيد التطوير وقد يكون هنالك نقص أو زيادة في بعض الشروح المعروضة.',
        in_progress_ambiguous_feature: 'هذه الخاصية ما زالت قيد الإنشاء.',
        in_progress_rulings_feature:
            'هذه الخاصية مازالت <b><u>قيد الإنشاء والتدقيق</b></u>، كما أنه يجري إدخال أحكام المزيد من <b><u>المتقدمين والمتأخرين</b></u>.',
        in_progress_takhreej_feature:
            'خاصية التخريج ما زالت قيد التطوير وقد يكون هنالك نقص أو زيادة في بعض الطرق المعروضة.',
        semantic_disclaimer:
            'هذه الخاصية ما زالت قيد التطوير فالنتائج تقريبية وقد يعتريها بعض الخطأ',
        semantic_disabled: 'غير متوفر عند البحث في معاني الأحاديث',
        hadith_ruling: 'حكم الحديث (الألباني) ',
        narrator: 'الراوي',
        search_ongoing: 'البحث جاري',
        ai_search_mobile: 'ابحث في معاني الحديث',
        ai_search: 'ابحث في معاني الحديث عن طريق الذكاء الإصطناعي',
        search_in_hadiths: 'ابحث في نصوص الأحاديث',
        search_in_hadiths_short: 'ابحث في الأحاديث',
        copy_config: 'إعدادات نسخ الحديث',
        word_config: 'إعدادات تحميل ملف النتائج',
        tasnif: 'التصنيف',
        tashkeel: 'التشكيل',
        tarqeem: 'الترقيم',
        takhreej: 'التخريج (قيد الإنشاء)',
        subChapter: 'الباب',
        theChapter: 'الكتاب',
        volume: 'جزء',
        page: 'صفحة',
        book: 'المصنف',
        number: 'رقم الحديث',
        clear_all: 'مسح جميع الاختيارات',
        export_to_excel: 'تصدير النتائج إلى إكسل',
        export_disabled: 'الحد الأقصى لخاصية التحميل هو ٢٠٠ حديث فقط',
        export_disabled_semantic: 'خاصية التحميل متوفرة فقط عند البحث في النص',
        no_results_found: 'لا يوجد نتائج ضمن خيارات البحث المختارة',
        semantic_no_results_founds:
            'لم يستطع الذكاء الإصطناعي تحديد نتائج مقاربة لعبارة البحث، يرجى التفصيل اكثر في العبارة والمحاولة مرة أخرى',
        relevant_results_mobile: 'نتائج',
        irrelevant_results_mobile: 'نتائج',
        relevant_results: 'نتائج قريبة',
        irrelevant_results: 'نتائج بعيدة',
        prev_search: 'العودة إلى البحث السابق',
        no_narrator_grades_found: 'لا يوجد درجات ضمن خيارات البحث المختارة',
        found: 'تم العثور على',
        no_hadith_rulings_found:
            'لا يوجد حكم للألباني ضمن خيارات البحث المختارة',
        filters: 'التصنيفات',
        intro_video_title: 'التعريف بالمنصة',
        features: 'مميزات المنصة',
        userguide_video_title: 'دليل المستخدم',
        close_drawer: 'إغلاق التصنيفات',
        video_welcome_text: 'أهلا بكم في المنصة الحديثية',
        results_matching_search_criteria_1: 'حديث واحد مطابق لخيارات البحث',
        results_matching_search_criteria_2: 'حديثان مطابقان لخيارات البحث',
        results_matching_search_criteria_gt10: 'حديث مطابق لخيارات البحث',
        results_matching_search_criteria_lt10: 'أحاديث مطابقة لخيارات البحث',
        results_no_filters: 'حديث',
        search_by_hadith_number: 'البحث برقم الحديث',
        commentaries_count: 'علل طرق وشواهد الحديث',
        commentary_books: 'مصنفات العلل',
        commentary_authors: 'المصنف',
        hadith_number: 'رقم الحديث',
        enter_hadith_number: 'أدخل رقم الحديث',
        books: 'المصنفات',
        books_of_roads: 'مصنفات طرق وشواهد الحديث',
        no_books_found: 'لا يوجد مصنفات ضمن خيارات البحث المختارة',
        explanation_authors: 'المصنف',
        explanation_books: 'مصنفات الشروح',
        ambiguous_books: 'مصنفات الغريب',
        ambiguous_authors: 'المصنف',
        search_for_a_narrator: 'ابحث عن راوي',
        search_for_a_scholar: 'ابحث عن شيخ',
        search_for_a_commentator: 'ابحث عن معلق',
        search_for_a_student: 'ابحث عن راوي',
        search_for_a_grade: 'ابحث عن درجة',
        search_for_a_verb: 'ابحث عن صيغة',
        narrators_linked_in_narration_chain: 'رواة مترابطون في السند',
        no_narrators_found: 'لا يوجد رواة ضمن خيارات البحث المختارة',
        degree: 'درجة السند',
        search: 'البحث',
        scholar_grade_search_more: 'ابحث عن باقي الدرجات',
        student_grade_search_more: 'ابحث عن باقي الدرجات',
        narrated_on: 'روى عن',
        narrated_on_his_behalf: 'روى عنه',
        narrator_search_more: 'ابحث عن باقي الرواة',
        commentary_author_search_more: 'ابحث عن باقي المعلقين',
        search_for_a_chapter: 'ابحث عن كتاب',
        search_for_a_sub_chapter: 'ابحث عن باب',
        search_for_a_root_keyword: 'ابحث عن متن جامع',
        search_for_a_ruling: 'ابحث عن حكم',
        chapter: 'كتاب',
        no_chapters_found: 'لا يوجد كتب ضمن خيارات البحث المختارة',
        no_sub_chapters_found: 'لا يوجد أبواب ضمن خيارات البحث المختارة',
        hadith_type: 'نوع الحديث',
        roads_type: 'أنواع طرق وشواهد الحديث',
        score: 'المقاربة',
        source: 'المصدر',
        textual: 'النص',
        semantic: 'المعنى',
        hadith_root_keywords: 'المتن الجامع',
        hadiths: 'الأحاديث',
        shawahed_no_matn_diff: 'خاصية مقارنة المتون غير متوفرة لشواهد الحديث',
        no_hadith_types_found:
            'لا يوجد أنواع للأحاديث ضمن خيارات البحث المختارة',
        sort_by_authenticity: 'الأوثق',
        sort_by_oldest: 'المتقدم',
        sort_by_latest: 'المتأخر',
        download_results: 'تحميل النتائج',
        narrators: 'الرواة',
        narratorsWithoutAl: 'رواة',
        commentator: 'المعلقون',
        albani: 'الألباني:',
        no_rulings_found: 'لا يوجد حكم ضمن خيارات البحث المختارة',
        no_validators_found: 'لا يوجد محدث ضمن خيارات البحث المختارة',
        no_scholars_found: 'لا يوجد شيوخ ضمن خيارات البحث المختارة',
        no_students_found: 'لا يوجد تلاميذ ضمن خيارات البحث المختارة',
        no_narrator_commentaries_found:
            'لا يوجد أحكام ضمن خيارات البحث المختارة',
        no_narrator_commentary_found: 'لا يوجد بيانات جرح وتعديل لهذا الراوي',
        commentary_count: 'عدد الأقوال',
        narrations_count: 'عدد المرويات',
        shawahed_count: 'شواهد الحديث',
        roads_count: 'عدد النتائج',
        chains_count: 'عدد الأسانيد',
        explanations_count: 'عدد الشروح',
        ambiguous_count: 'شروح غريب الحديث',
        rulings_count: 'عدد الأحكام',
        narrators_count: 'عدد الرواة في هذا الحديث',
        narrator_list_count: 'عدد الرواة',
        no_hadith_verbs_found: 'لا يوجد صيغ تحديث ضمن خيارات البحث المختارة',
        chapters: 'الكتب',
        sub_chapters: 'الأبواب',
        select_all: 'اختر الكل',
        of: 'من',
        results: 'نتائج',
        rows_per_page: 'صفوف في الصفحة',
        ambiguous_words: 'الغريب',
        ambiguous_words_withoutAl: 'غريب',
        shawahed: 'شواهد',
        roads: 'الطرق',
        shawahed_and_roads: 'طرق وشواهد',
        roadsWithoutAl: 'تخريج',
        rulings: 'الحكم',
        rulingsWithoutAl: 'حكم',
        rulers: 'المحدثين',
        commentary: 'العلل',
        commentaryWithoutAl: 'علل',
        explanation: 'الشروح',
        explanationWithoutAl: 'شروح',
        subchapter_hadiths: 'أحاديث الباب',
        hadiths_count: 'عدد الأحاديث',
        matn: 'متن',
        similar_matn: 'متن مقارب للمعنى',
        hadith: 'حديث',
        narrators_commentary_count: 'عدد الأحكام',
        bio: 'ترجمة الراوي',
        scholars: 'روى عن',
        scholar: 'الشيخ',
        students: 'روي عنه',
        student: 'التلميذ',
        students_filter: 'روى عنه',
        scholars_filter: 'روى عن',
        narrator_commentary: 'الجرح و التعديل',
        scholar_grade: 'درجة الراوي ( ابن حجر [ت ت] )',
        student_grade: 'درجة الراوي ( ابن حجر [ت ت] )',
        hadith_delivering_verb: 'صيغة التحديث',
        label_narrator: 'عن الراوي',
        label_prophet: 'Prophet',
        label_author: 'المؤلف',
        label_full_name: 'الاسم',
        label_keywords: 'الطرف',
        label_root_keywords: 'المتن الجامع',
        label_extended_full_name: 'الاسم الكامل',
        label_nickname: 'الكنية',
        label_origin: 'النسب',
        label_lived_in: 'عاش في',
        label_died_in: 'بلد الوفاة',
        label_died_on: 'تاريخ الوفاة',
        label_born_in: 'ولد في',
        label_born_on: 'المولد',
        label_commentary: 'الجرح و التعديل',
        hadith_receiving_verb: 'صيغة التلقي',
        commentators: 'المعلقين',
        graph: 'التشجير',
        graph_loading: 'جار تشجير الحديث...',
        matn_comparison: 'مقارنة المتون',
        matn_removal: 'حذف',
        matn_addition: 'إضافة',
        commentaries_count_with_filters:
            'عدد علل طرق وشواهد الحديث المطابقة للبحث',
        narrations_count_with_filters: 'عدد المرويات المطابقة للبحث',
        shawahed_count_with_filters: 'عدد شواهد الحديث المطابقة للبحث',
        roads_count_with_filters: 'عدد النتائج المطابقة للبحث',
        chains_count_with_filters: 'عدد الأسانيد المطابقة للبحث',
        explanations_count_with_filters: 'عدد الشروح المطابقة للبحث',
        rulings_count_with_filters: 'عدد الأحكام المطابقة للبحث',
        hadiths_count_with_filters: 'عدد الأحاديث المطابقة للبحث',
        matns_count_with_filters: 'عدد المتون المقاربة في المعنى',
        narrators_commentary_count_with_filters: 'عدد الأحكام المطابقة للبحث',
        exact_match: 'بحث مطابق',
        inexact_match: 'بحث موسع',
        exact: 'مطابق',
        text_search: 'بحث في النص',
        inexact_matches: 'نتائج موسعة',
        inexact_matches_short: 'موسعة',
        semantic_search: 'بحث في المعنى (الذكاء الإصطناعي)',
        semantic_search_short: 'بحث في المعنى',
        show_hadith: 'إظهار الحديث',
        hide_hadith: 'إخفاء الحديث',
        sort_by: 'ترتيب حسب',
        from_oldest_to_newest: 'الأقدم إلى الأحدث',
        from_newest_to_oldest: 'الأحدث إلى الأقدم',
        authenticity: 'درجة صحة المصنف',
        relevance: 'كلمات البحث',
        similarity: 'درجة المطابقة',
        toggle_verb: 'إظهار صيغة التحديث',
        sahih_bukhari: 'صحيح البخاري',
        sahih_muslim: 'صحيح مسلم',
        sunan_abu_dawud: 'سنن أبي داود',
        jamia_tirmidhi: 'جامع الترمذي',
        sunan_al_nasaai_al_sughra: 'سنن النسائي الصغرى',
        sunan_ibn_majah: 'سنن ابن ماجه',
        mwata: 'موطأ مالك رواية يحيى الليثي',
        sunan_darimy: 'سنن الدارمي',
        musnad_ahmad: 'مسند أحمد بن حنبل',
        sahih_ibnhaban: 'صحيح ابن حبان',
        sahih_ibnkhuzaymah: 'صحيح ابن خزيمة',
        mustadrak: 'المستدرك على الصحيحين',
        read_more: 'اقرأ المزيد',
        read_less: 'اقرأ أقل',
        report_an_error: 'إخبارنا عن خطأ',
        report_an_error_description:
            'يرجى تعبئة البيانات التالية حتى يمكنك مراسلتنا',
        email: 'البريد الإلكتروني',
        your_message: 'رسالتك',
        send: 'إرسال',
        copy_hadith: 'نسخ الحديث',
        download_word: 'تحميل ملف النتائج',
        update: 'تحديث',
        keywords_updated: 'تم التحديث',
        keywords_error: 'كان هناك خلل في التحديث',
        fetch_error:
            'كان هناك خطأ في تحميل النتائج. الرجاء المحاولة مرة أخرى أو التواصل معنا إذا إستمر الخطأ.',
        chains_updated: 'تم التحديث',
        update_in_progress: 'جاري التحديث...',
        verb_search: 'ابحث عن صيغة تحديث...',
        chains_error: 'كان هناك خلل في التحديث',
        invalid_keywords:
            'الطرف الذي تم إختياره لا يطابق أي جزء من الحديث. تأكد من صحة الطرف وجميع التشكيل.',
        required_field: 'حقل مطلوب',
        invalid_email: 'البريد الإلكتروني غير صحيح',
        email_sent_successfully: 'تم إرسال الرسالة',
        report_failed:
            'كان هناك مشكلة في إرسال البلاغ. الرجاء الإنتظار والمحاولة مرة أخرى.',
        error_reported_successfully: 'تم الإبلاغ عن خطأ',
        full_screen_disabled: 'خاصية ملئ الشاشة ليست مدعومة من قبل جهازك',
        shawahed_not_clickable: 'لا يوجد شواهد لهذا الحديث ضمن قاعدة البيانات',
        extended_explanations_disabled: 'لا يوجد شروح لشواهد هذا الحديث',
        extended_rulings_disabled: 'لا يوجد أحكام على شواهد هذا الحديث',
        preview_not_clickable: 'خاصية تصفح الكتاب غير متوفرة لهذا النص',
        ruling_preview_not_avilable:
            'خاصية تصفح الكتاب لهذا الحكم ماتزال قيد الإنشاء',
        roads_not_clickable: 'لا يوجد طرق أخرى لهذا الحديث ضمن قاعدة البيانات',
        narrators_not_clickable: 'لا يوجد تفاصيل لرواة هذا الحديث',
        commentary_not_clickable:
            'لا يوجد علل لهذا الحديث أو طرقه ضمن قاعدة البيانات',
        ruling_not_clickable:
            'لا يوجد حكم على هذا الحديث أو طرقه ضمن قاعدة البيانات',
        ambiguous_not_clickable: 'لا يوجد شرح لغريب الحديث ضمن قاعدة البيانات',
        explanation_not_clickable:
            'لا يوجد شروح لهذا الحديث أو طرقه ضمن قاعدة البيانات',
        feature_not_available: 'هذه الخاصية غير متاحة حالياً',
        feature_not_available_on_mobile:
            'هذه الخاصية غير متاحة على أجهزة المحمول',
        hadith_copied: 'تم نسخ الحديث',
        url_copied: 'تم نسخ الرابط للمشاركة',
        matn_copied: 'تم نسخ المتن',
        matn_info: 'عدد المتون التقريبي بعد حذف المكرر',
        view_original_hadith: 'عرض الحديث',
        narrations: 'الروايات',
        view_narrations: 'عرض الروايات',
        font_size: 'حجم الخط',
        small_font: 'صغير',
        normal_font: 'عادي',
        large_font: 'كبير',
        verylarge_font: 'كبير جداً',
        tashkeel_nas: 'تشكيل النصوص',
    },
    narratorBio: {
        _id: 'id',
        name: 'الاسم',
        full_name: 'الاسم الكامل',
        extended_fullname: 'الاسم الكامل',
        nickname: 'الكنية',
        origin: 'النسب',
        month: 'شهر',
        born_on: 'تاريخ الولادة',
        lived_in: 'عاش في',
        level: 'الطبقة (ابن حجر)',
        died_in: 'تاريخ الوفاة',
        died_on: 'توفي عام',
        book_titles: 'الكتب',
        grade: ' الدرجة ( ابن حجر [ت ت] )',
        date_of_death: 'توفي عام',
        city: 'المدينة',
        narrator_bio: 'نبذة عن الراوي',
        commentary: 'الجرح والتعديل',
        top_students: 'اكثر من روى عنه',
        top_scholars: 'اكثر من روى عنهم',
    },
    references: {
        preview_disclaimer:
            'خاصية ربط الأحاديث بصفحات الكتب ما تزال قيد التطوير وقد يوجد أخطاء في الربط',
        bookName: 'اسم الكتاب',
        fullBookName: 'اسم الكتاب كامل',
        author: 'المؤلف',
        verifier: 'المحقق',
        publisher: 'الناشر',
        edition: 'الطبعة',
        type: 'القسم',
        numOfParts: 'عدد الأجزاء',
        status: 'حالة الكتاب',
        progress: 'نسبة الاكتمال',
        filters: 'التصنيفات',
        edition_tooltip: 'الطبعة المستخدمة في الترقيم',
        select_all: 'اختر الكل',
        references_count: 'عدد المراجع',
        not_available: 'غير متوفر',
        search_for_an_author: 'ابحث عن مؤلف',
        results_matching_search_criteria_1: 'مرجع واحد مطابق لخيارات البحث',
        results_matching_search_criteria_2: 'مرجعان مطابقان لخيارات البحث',
        results_matching_search_criteria_gt10: 'مرجع مطابق لخيارات البحث',
        results_matching_search_criteria_lt10: 'مراجع مطابقة لخيارات البحث',
        author_filter_header: 'المؤلف',
        book_count_label: 'عدد المراجع: ',
        clear_all: 'مسح جميع الاختيارات',
        apply: 'تطبيق',
        status_filter_header: 'حالة الكتب',
        book_type_filter_header: 'أقسام الكتب',
        search_in_references: 'ابحث في المراجع',
        no_results_found: 'لا يوجد مراجع ضمن خيارات البحث المختارة',
        references_count_with_filters: 'عدد المراجع المطابقة للبحث',
        book_perview_not_clickable: 'هذه الخاصية غير متوفرة لهذا الكتاب حالياً',
        quick_navigation: 'التنقل السريع',
    },
    '404': {
        message:
            'الصفحة التي تحاول الوصول إليها غير موجودة. الرجاء إستخدام الروابط في أعلى الصفحة للتنقل إلى صفحة أخرى.',
    },
    bot: {
        message:
            'نظامنا يظهر أنك تستخدم بوت لتصفح موقعنا أو لتحميل البيانات. الرجاء التواصل معنا إن كان هناك خطأ أو إن كنت ترغب بإستخدام البيانات لأغراض خاصة.',
    },
    offline: {
        message: 'لقد فقدت الإتصال بالشبكة',
        retry: 'إعادة الإتصال',
    },
    privacy: {
        policy_title: 'سياسة الخصوصية للمنصة الحديثية',
        policy: 'قام مركز تميز ببناء تطبيق المنصة الحديثية كتطبيق مجاني. هذه الخدمة مقدمة من مركز تميز بدون تكلفة وهي مخصصة للاستخدام كما هي. تُستخدم هذه الصفحة لإعلام الزائرين بسياساتنا بجمع المعلومات الشخصية واستخدامها والكشف عنها إذا قرر أي شخص استخدام خدمتنا. إذا اخترت استخدام خدمتنا ، فأنت توافق على جمع واستخدام المعلومات المتعلقة بهذه السياسة. تُستخدم المعلومات الشخصية التي نجمعها لتوفير الخدمة وتحسينها. لن نستخدم أو نشارك معلوماتك مع أي شخص باستثناء ما هو موضح في سياسة الخصوصية هذه. المصطلحات المستخدمة في سياسة الخصوصية هذه لها نفس المعاني الموجودة في الشروط والأحكام الخاصة بنا ، والتي يمكن الوصول إليها في المنصة الحديثية ما لم يتم تحديد خلاف ذلك في سياسة الخصوصية هذه.',
        collection_and_use_title: 'جمع المعلومات واستخدامها',
        collection_and_use:
            'للحصول على تجربة أفضل ، أثناء استخدام خدمتنا ، قد نطلب منك تزويدنا بمعلومات تعريف شخصية معينة. سيتم الاحتفاظ بالمعلومات التي نطلبها من قبلنا واستخدامها كما هو موضح في سياسة الخصوصية هذه. يستخدم التطبيق خدمات الجهات الخارجية التي قد تجمع المعلومات المستخدمة لتحديد هويتك. رابط لسياسة الخصوصية لموفري خدمات الطرف الثالث التي يستخدمها التطبيق',
        log_data_title: 'تسجيل البيانات',
        log_data:
            'نود إعلامك أنه كلما استخدمت خدمتنا ، في حالة حدوث خطأ في التطبيق ، نقوم بجمع البيانات والمعلومات (من خلال منتجات الجهات الخارجية) على هاتفك تسمى Log Data. قد تتضمن بيانات السجل هذه معلومات مثل عنوان بروتوكول الإنترنت ("IP") الخاص بجهازك ، واسم الجهاز ، وإصدار نظام التشغيل ، وتكوين التطبيق عند استخدام خدمتنا ، ووقت وتاريخ استخدامك للخدمة ، وإحصاءات أخرى .',
        cookies_title: 'إعدادات ملفات تعريف الارتباط',
        cookies_1:
            'إعدادات ملفات تعريف الارتباط هي ملفات تحتوي على كمية صغيرة من البيانات التي يتم استخدامها بشكل شائع كمعرفات فريدة مجهولة الهوية. يتم إرسالها إلى متصفحك من مواقع الويب التي تزورها ويتم تخزينها على الذاكرة الداخلية لجهازك.',
        cookies_2:
            'لا تستخدم هذه الخدمة "ملفات تعريف الارتباط" بشكل صريح. ومع ذلك ، قد يستخدم التطبيق رموزًا ومكتبات خاصة بطرف ثالث تستخدم "ملفات تعريف الارتباط" لجمع المعلومات وتحسين خدماتهم. لديك خيار إما قبول أو رفض ملفات تعريف الارتباط هذه ومعرفة متى يتم إرسال ملف تعريف الارتباط إلى جهازك. إذا اخترت رفض ملفات تعريف الارتباط الخاصة بنا ، فقد لا تتمكن من استخدام بعض أجزاء هذه الخدمة.',
        service_providers_title: 'مقدمي الخدمة',
        service_providers:
            'يجوز لنا توظيف شركات وأفراد تابعين لجهات خارجية للأسباب التالية:',
        service_providers_reasons: {
            1: 'لتسهيل خدمتنا؛',
            2: 'لتقديم الخدمة نيابة عنا؛',
            3: 'لأداء الخدمات المتعلقة بالخدمة؛',
            4: 'لمساعدتنا في تحليل كيفية استخدام خدمتنا.',
        },
        service_providers_2:
            'نريد إبلاغ مستخدمي هذه الخدمة أن هذه الأطراف الثالثة لديها حق الوصول إلى معلوماتك الشخصية. والسبب هو أداء المهام الموكلة إليهم نيابة عنا. ومع ذلك ، فهم ملزمون بعدم الكشف عن المعلومات أو استخدامها لأي غرض آخر.',
        security_title: 'حماية',
        security:
            'نحن نقدر ثقتك في تزويدنا بمعلوماتك الشخصية ، وبالتالي فإننا نسعى جاهدين لاستخدام وسائل مقبولة تجاريًا لحمايتها. لكن تذكر أنه لا توجد وسيلة نقل عبر الإنترنت أو طريقة تخزين إلكتروني آمنة وموثوقة بنسبة 100٪ ، ولا يمكننا ضمان أمانها المطلق.',
        links_to_other_sites_title: 'روابط لمواقع أخرى',
        links_to_other_sites:
            'قد تحتوي هذه الخدمة على روابط لمواقع أخرى. إذا قمت بالنقر فوق ارتباط جهة خارجية ، فسيتم توجيهك إلى هذا الموقع. لاحظ أن هذه المواقع الخارجية لا يتم تشغيلها بواسطتنا. لذلك ، ننصحك بشدة بمراجعة سياسة الخصوصية الخاصة بهذه المواقع. ليس لدينا أي سيطرة ولا نتحمل أي مسؤولية عن المحتوى أو سياسات الخصوصية أو الممارسات الخاصة بأي مواقع أو خدمات تابعة لجهات خارجية.',
        rights_title: 'لديك الحق بموجب سياسة الخصوصية هذه والقانون:',
        rights: 'لطلب الوصول إلى بياناتك الشخصية. الحق في الوصول إلى المعلومات التي لدينا عنك أو تحديثها أو حذفها. وكذلك طلب محو بياناتك الشخصية. يحق لك مطالبتنا بحذف البيانات الشخصية أو إزالتها عندما لا يكون هناك سبب وجيه لمواصلة معالجتها.',
        contact_us_title: 'التواصل معنا',
        contact_us:
            'إذا كان لديك أي أسئلة أو اقتراحات حول سياسة الخصوصية الخاصة بنا ، فلا تتردد في الاتصال بنا على support@tamayyuzcenter.com',
    },
};
