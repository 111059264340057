import { Fragment } from 'react';

import {
    ButtonBase,
    Divider,
    Grid,
    SvgIcon,
    SvgIconTypeMap,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { HadithActionsContainer, HadithActionsWrapper } from './styled';

interface IHadithRightAction {
    text: string;
    action: () => void;
    isClickable: boolean;
    // message to explain why it's not clickable
    notClickableMessage?: string;
    // for gharib hadith btn
    fullWidth?: boolean;
}

interface IHadithLeftAction {
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
    action: () => void;
    isClickable?: boolean;
    hasBorderRight?: boolean;
    // message to explain why it's not clickable
    notClickableMessage?: string;
}

const HadithActions = ({
    rightActions = [],
    leftActions = [],
}: {
    rightActions: IHadithRightAction[];
    leftActions: IHadithLeftAction[];
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (!leftActions.length && !rightActions.length) {
        return null;
    }
    return (
        <>
            <Divider />
            <HadithActionsWrapper>
                <HadithActionsContainer>
                    <Grid container display={{ xs: 'block', sm: 'flex' }}>
                        <Grid
                            item
                            xs={12}
                            sm={8}
                            display="flex"
                            flexDirection="row"
                            justifyContent={{
                                xs: 'space-evenly',
                                sm: 'flex-start',
                            }}
                        >
                            {rightActions.map(
                                (
                                    {
                                        text,
                                        action,
                                        isClickable,
                                        notClickableMessage,
                                        fullWidth,
                                    },
                                    i,
                                ) => {
                                    const textColor = isClickable
                                        ? 'secondary'
                                        : 'rgb(211,211,211)';
                                    const hoverCursor = isClickable
                                        ? 'pointer'
                                        : 'not-allowed';
                                    const hoverBackground = isClickable
                                        ? 'rgba(183, 164, 164, 0.1)'
                                        : 'white';

                                    return (
                                        <Fragment key={`${text}-${i}`}>
                                            <Tooltip
                                                placement="top-start"
                                                // only display for disabled tabs
                                                title={
                                                    isClickable ? (
                                                        ''
                                                    ) : (
                                                        <Typography
                                                            fontSize={12}
                                                        >
                                                            {
                                                                notClickableMessage
                                                            }
                                                        </Typography>
                                                    )
                                                }
                                                // no long press, immediate
                                                enterTouchDelay={1}
                                            >
                                                <ButtonBase
                                                    component="div"
                                                    key={`${text}-${i}`}
                                                    onClick={() => {
                                                        if (isClickable) {
                                                            action();
                                                        }
                                                    }}
                                                    sx={{
                                                        padding: '10px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'right',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                hoverBackground,
                                                            cursor: hoverCursor,
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        color={textColor}
                                                    >
                                                        {text}
                                                    </Typography>
                                                </ButtonBase>
                                            </Tooltip>

                                            {i !== rightActions.length - 1 && (
                                                <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                />
                                            )}
                                        </Fragment>
                                    );
                                },
                            )}
                        </Grid>

                        {leftActions.length > 0 && (
                            <>
                                {isMobile && <Divider />}
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent={{
                                        xs: 'space-evenly',
                                        sm: 'flex-end',
                                    }}
                                >
                                    {leftActions.map(
                                        (
                                            {
                                                icon,
                                                action,
                                                isClickable,
                                                hasBorderRight,
                                                notClickableMessage,
                                            },
                                            i,
                                        ) => {
                                            const hoverCursor = isClickable
                                                ? 'pointer'
                                                : 'not-allowed';
                                            const hoverBackground = isClickable
                                                ? 'rgba(183, 164, 164, 0.1)'
                                                : 'white';

                                            return (
                                                <Fragment key={i}>
                                                    <Tooltip
                                                        placement="top"
                                                        // only display for disabled tabs
                                                        title={
                                                            isClickable ? (
                                                                ''
                                                            ) : (
                                                                <Typography
                                                                    fontSize={
                                                                        12
                                                                    }
                                                                >
                                                                    {
                                                                        notClickableMessage
                                                                    }
                                                                </Typography>
                                                            )
                                                        }
                                                        // no long press, immediate
                                                        enterTouchDelay={1}
                                                    >
                                                        <ButtonBase
                                                            component="div"
                                                            key={i}
                                                            onClick={() => {
                                                                if (
                                                                    isClickable
                                                                ) {
                                                                    action();
                                                                }
                                                            }}
                                                            sx={{
                                                                padding: '10px',
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    {
                                                                        xs: 'center',
                                                                        sm: 'right',
                                                                    },
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        hoverBackground,
                                                                    cursor: hoverCursor,
                                                                },
                                                                borderRight:
                                                                    hasBorderRight
                                                                        ? '1px solid lightgray'
                                                                        : 'none',
                                                                flex: {
                                                                    xs: 1,
                                                                    sm: 0,
                                                                },
                                                                pl: hasBorderRight
                                                                    ? {
                                                                          xs: '4px',
                                                                          sm: '10px',
                                                                      }
                                                                    : '10px',
                                                                // width: {
                                                                //     xs: '100%',
                                                                //     sm: 'inherit',
                                                                // },
                                                            }}
                                                        >
                                                            <SvgIcon
                                                                component={icon}
                                                                color={
                                                                    isClickable
                                                                        ? 'secondary'
                                                                        : 'disabled'
                                                                }
                                                            />
                                                        </ButtonBase>
                                                    </Tooltip>
                                                    {/* {i !==
                                                        leftActions.length -
                                                            1 && (
                                                        <Divider
                                                            orientation="vertical"
                                                            flexItem
                                                        />
                                                    )} */}
                                                </Fragment>
                                            );
                                        },
                                    )}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </HadithActionsContainer>
            </HadithActionsWrapper>
        </>
    );
};

export { HadithActions };
export type { IHadithLeftAction, IHadithRightAction };
