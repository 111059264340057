export type Translation = typeof translationsEn;

export const translationsEn = {
    layout: {
        follow_us: 'Follow us',
        trial_version: 'Beta version',
        navbar_cta_button: 'Contact us',
        navbar_link_hadiths: 'Hadiths',
        navbar_link_narrators: 'Narrators',
        navbar_link_ai: 'Semantic Search',
        navbar_link_references: 'References',
        navbar_settings_references: 'Settings',
        navbar_settings_references_with_l: 'Settings',
        navbar_link_who_we_are: 'About us',
        navbar_link_more: 'More...',
        navbar_link_contact_short: 'تواصل',
        navbar_link_contact: 'Contact us',
        navbar_link_userguide: 'User guide',
        copyright: 'Copyright',
        footer_link_1: 'Link 1',
        footer_link_2: 'Link 2',
        footer_link_3: 'Link 3',
        footer_link_4: 'Link 4',
        english: 'English',
        arabic: 'Arabic',
        search_more: 'Search for more',
        appstore: 'Get the mobile app for Iphones',
        playstore: 'Get the mobile app for Android',
    },
    library: {
        in_progress_roads_graph_feature: 'In Progress roads feature',
        in_progress_roads_hadith_feature: 'In Progress roads feature',
        in_progress_ambiguous_feature: 'In progress feature.',
        in_progress_explainations_feature:
            'In Progress explainationsFeature Placeholder',
        in_progress_narrations_feature:
            'In Progress narrations Feature Placeholder',
        in_progress_rulings_feature: 'in progress',
        in_progress_takhreej_feature:
            'خاصية نسخ التخريج ما زالت قيد التطوير وقد يكون هنالك نقص أو زيادة في بعض الطرق المعروضة.',
        semantic_disclaimer:
            'هذه الخاصية ما زالت قيد التطوير وهي تعتمد على الذكاء الإصطناعي فقد يعتريها بعض الخطأ',
        semantic_disabled: 'غير متوفر عند البحث في معاني الأحاديث',
        hadith_ruling: 'Hadith ruling',
        search_ongoing: 'Search Ongoing',
        commentary: 'Commentary',
        commentaryWithoutAl: 'Commentary',
        ai_search_mobile: 'ابحث في معاني الحديث',
        ai_search: 'ابحث في معاني الحديث عن طريق الذكاء الإصطناعي',
        search_in_hadiths: 'Search in hadiths',
        search_in_hadiths_short: 'ابحث في الأحاديث',
        copy_config: 'Hadith Copy Settings',
        word_config: 'Word Export Settings',
        tasnif: 'filters',
        tashkeel: 'تشكيل',
        tarqeem: 'الترقيم',
        takhreej: 'تخريج',
        subChapter: 'الباب',
        theChapter: 'الكتاب',
        volume: 'volume',
        page: 'page',
        book: 'المصنف',
        number: 'رقم الحديث',
        clear_all: 'Clear All',
        no_results_found: 'No results found',
        semantic_no_results_founds:
            'لم يستطع الذكاء الإصطناعي تحديد نتائج مقاربة لعبارة البحث, يرجى التفصيل اكثر في العبارة والمحاولة مرة أخرى',
        relevant_results_mobile: 'نتائج',
        irrelevant_results_mobile: 'نتائج',
        relevant_results: 'نتائج قريبة',
        irrelevant_results: 'نتائج بعيدة',
        found: 'found',
        results_matching_search_criteria_lt10:
            'Results matching search criteria',
        results_matching_search_criteria_gt10:
            'Results matching search criteria',
        results_no_filters: '',
        no_hadith_rulings_found: 'No Albani rulings found',
        search_by_hadith_number: 'Search by hadith number',
        hadith_number: 'Hadith number',
        enter_hadith_number: 'Enter hadith number',
        books: 'Books',
        books_of_roads: 'مصنفات طرق الحديث',
        no_books_found: 'No books found',
        sort_by_authenticity: 'Authenticity',
        sort_by_oldest: 'Oldest',
        sort_by_latest: 'Latest',
        search_for_a_narrator: 'Search for a narrator',
        video_welcome_text: 'Welcome to Hadith Platform',
        intro_video_title: 'Platform Intro',
        features: 'مميزات المنصة',
        userguide_video_title: 'User Guide',
        narrators_linked_in_narration_chain:
            'Narrators linked in narration chain',
        no_narrators_found: 'No narrators found',
        no_scholars_found: 'No scholars found',
        search_for_a_scholar: 'Search for a scholar',
        degree: 'Degree',
        search_for_a_chapter: 'Search for a chapter',
        search_for_a_commentator: 'Search for a commentator',
        search_for_a_sub_chapter: 'Search for a sub chapter',
        search_for_a_root_keyword: 'search_for_a_root_keyword',
        chapter: 'Chapter',
        no_chapters_found: 'No chapters found',
        no_sub_chapters_found: 'No sub chapters found',
        hadith_type: 'Hadith type',
        roads_type: 'أنواع طرق الحديث',
        score: 'درجة المقاربة',
        source: 'المصدر',
        textual: 'النص',
        semantic: 'المعنى',
        hadith_root_keywords: 'Root Keywords',
        no_hadith_types_found: 'No hadith types found',
        download_results: 'Download results',
        narrators: 'Narrators',
        narratorsWithoutAl: 'Narrators',
        ambiguous_count: 'Explanation of ambiguous words',
        no_rulings_found: 'No rulings found',
        ambiguous_books: 'Ambiguous Books',
        ambiguous_authors: 'Authors',
        no_validators_found: 'No validators found',
        chapters: 'Chapters',
        sub_chapters: 'Sub Chapters',
        search_for_a_student: 'Search for a student',
        no_students_found: 'No student found',
        narrations_count: 'Narrations count',
        shawahed_count: 'Shawahed count',
        roads_count: 'Roads count',
        chains_count: 'Chains count',
        narrators_count: 'Narrators count',
        narrator_list_count: 'Narrators count',
        no_narrator_grades_found: 'No grades found',
        filters: 'Filters',
        close_drawer: 'Close Filters',
        select_all: 'Select all',
        of: 'of',
        results: 'results',
        rows_per_page: 'Rows per page',
        scholar_grade: 'Scholar Grade (Taqreeb AlTahzeeb)',
        student_grade: 'Student Grade (Taqreeb AlTahzeeb)',
        hadith_delivering_verb: 'Delivering Verb',
        label_narrator: 'Narrator',
        label_prophet: 'Prophet',
        label_author: 'Author',
        label_full_name: 'Full name',
        label_keywords: 'Keywords',
        label_root_keywords: 'Root keywords',
        label_extended_full_name: 'Extended full name',
        label_nickname: 'Nickname',
        label_origin: 'Origin',
        label_lived_in: 'Lived in',
        label_died_in: 'Died in',
        label_died_on: 'Died on',
        label_born_in: 'Born in',
        label_born_on: 'Born on',
        label_commentary: 'Commentary',
        hadith_receiving_verb: 'Receiving Verb',
        commentators: 'Commentators',
        graph: 'Graph',
        matn_comparison: 'Matn Comparison',
        matn_removal: 'Removed',
        matn_addition: 'Added',
        narrator: 'Narrator',
        prev_search: 'Expand Your Search',
        results_matching_search_criteria_1: '',
        results_matching_search_criteria_2: '',
        commentaries_count: 'Commentaries Count',
        commentary_books: 'Commentary Books',
        commentary_authors: 'Commentary Authors',
        explanation_authors: 'Explanation Authors',
        explanation_books: 'Explanation Books',
        search_for_a_grade: 'Search for a Grade',
        search_for_a_verb: 'Search for a Verb',
        search: 'Search',
        scholar_grade_search_more: 'إبحث عن باقي الدرجات',
        student_grade_search_more: 'إبحث عن باقي الدرجات',
        narrator_search_more: 'ابحث عن باقي الرواة',
        commentary_author_search_more: 'ابحث عن باقي المعلقين',
        narrated_on: 'روى عن',
        narrated_on_his_behalf: 'روي عنه',
        search_for_a_ruling: 'Search for a Ruling',
        hadiths: 'Hadiths',
        commentator: 'Commentator',
        albani: 'Albani',
        no_narrator_commentaries_found: 'No Narrator Commentaries Found',
        commentary_count: 'Commentary Count',
        explanations_count: 'Explanations Count',
        rulings_count: 'Rulings Count',
        no_hadith_verbs_found: 'No Hadith Verbs Found',
        ambiguous_words: 'Ambiguous Words',
        ambiguous_words_withoutAl: 'Ambiguous Words',
        shawahed: 'Analogs',
        shawahed_and_roads: 'طرق وشواهد',
        roads: 'Roads',
        roadsWithoutAl: 'Roads',
        rulings: 'Rulings',
        rulingsWithoutAl: 'Rulings',
        rulers: 'Rulers',
        explanation: 'Explanation',
        explanationWithoutAl: 'Explanation',
        subchapter_hadiths: 'Subchapter Hadiths',
        hadiths_count: 'Hadiths Count',
        matn: 'Matn',
        similar_matn: 'متن مقارب للمعنى',
        hadith: 'hadith',
        narrators_commentary_count: 'Narrators Commentary Count',
        bio: 'Bio',
        scholars: 'Scholars',
        scholar: 'Scholar',
        scholars_filter: 'Scholars',
        students: 'Students',
        student: 'Student',
        students_filter: 'Studnets',
        narrator_commentary: 'Narrator Commentary',
        graph_loading: 'Graph Loading',
        commentaries_count_with_filters: 'Commentaries Count With Filters',
        narrations_count_with_filters: 'Narrations Count With Filters',
        shawahed_count_with_filters: 'Shawahed Count with Filters',
        roads_count_with_filters: 'Roads Count With Filters',
        chains_count_with_filters: 'Chains Count With Filters',
        explanations_count_with_filters: 'Explanations Count With Filters',
        rulings_count_with_filters: 'Rulings Count With Filters',
        hadiths_count_with_filters: 'Hadiths Count With Filters',
        matns_count_with_filters: 'عدد المتون المقاربة في المعنى',
        narrators_commentary_count_with_filters:
            'Narrators Commentary Count With Filters',
        similarity: 'Similarity Match',
        toggle_verb: 'Show Narration Verb',
        export_to_excel: 'Export results to Excel',
        export_disabled:
            'Export is only available for search with fewer than 200 results',
        export_disabled_semantic: 'خاصية التحميل متوفرة فقط عند البحث في النص',
        sort_by: 'Sort By',
        from_oldest_to_newest: 'From Oldest to Newest',
        from_newest_to_oldest: 'From Newest to Oldest',
        authenticity: 'Authenticity',
        relevance: 'Relevance',
        exact_match: 'Exact Match',
        inexact_match: 'بحث موسع',
        exact: 'مطابق',
        text_search: 'بحث في النص',
        inexact_matches: 'Inexact Matches',
        inexact_matches_short: 'Inexact',
        semantic_search: 'بحث في المعنى (الذكاء الإصطناعي)',
        semantic_search_short: 'بحث في المعنى',
        shawahed_no_matn_diff: 'خاصية مقارنة المتون غير متوفرة لشواهد الحديث',
        show_hadith: 'Show Hadith',
        hide_hadith: 'Hide Hadith',
        sahih_bukhari: 'Sahih Bukhari',
        sahih_muslim: 'Sahih Muslim',
        sunan_abu_dawud: 'Sunan Abu Dawud',
        jamia_tirmidhi: 'Jamia Tirmidhi',
        sunan_al_nasaai_al_sughra: 'Sunan Al-Nasaai Al-Sughra',
        mwata: 'mwata',
        sunan_darimy: 'sunan_darimy',
        no_narrator_commentary_found: 'No commentary found for this rawi',
        musnad_ahmad: 'musnad_ahmad',
        sahih_ibnhaban: 'sahih_ibnhaban',
        sahih_ibnkhuzaymah: 'sahih_ibnkhuzaymah',
        mustadrak: 'mustadrak',
        sunan_ibn_majah: 'Sunan Ibn Majah',
        read_more: 'Read More',
        read_less: 'Read Less',
        report_an_error: 'Report an Error',
        report_an_error_description: 'Please fill in the following information',
        email: 'Email',
        your_message: 'Your Message',
        send: 'Send',
        update: 'Update',
        copy_hadith: 'Copy Hadith',
        download_word: 'Download Word Document',
        keywords_updated: 'Keywords updated successfully',
        keywords_error: 'Error updating keywords',
        fetch_error:
            'كان هناك خطأ في تحميل النتائج. الرجاء المحاولة مرة أخرى أو التواصل معنا إذا إستمر الخطأ.',
        chains_updated: 'Chain updated successfully',
        update_in_progress: 'Updating...',
        verb_search: 'ابحث عن صيغة تحديث...',
        feature_not_available_on_mobile:
            'This feature is not available on mobile',
        chains_error: 'There was an error updating the chain',
        invalid_keywords:
            "The selected keywords are invalid and weren't found as part of the Hadith matn.",
        required_field: 'This is a required field',
        invalid_email: 'Invalid email',
        email_sent_successfully: 'Email sent successfully',
        report_failed:
            'There was an error contacing us. Please wait and try again shortly.',
        error_reported_successfully: 'Error reported successfully',
        full_screen_disabled:
            'Full screen feature is not available on this device',
        shawahed_not_clickable:
            'There are no available analogs for this Hadith',
        extended_explanations_disabled: 'لا يوجد شروح لشواهد هذا الحديث',
        extended_rulings_disabled: 'لا يوجد أحكام لشواهد هذا الحديث',
        ruling_preview_not_avilable:
            'The preview feature for this ruling is still under construction',
        preview_not_clickable:
            'Book Preview feature is not available for this Hadith',
        roads_not_clickable:
            'There are no other roads of narrations in the database',
        narrators_not_clickable:
            'The narrator details for this Hadith are not available',
        commentary_not_clickable:
            'The commentaries on this Hadith or its roads of narration are not available in the database',
        ruling_not_clickable:
            'The rulings on this Hadith or its roads of narration are not available in the database',
        ambiguous_not_clickable:
            'Ambiguos words are not available in the database',
        explanation_not_clickable:
            'The explanations for this Hadith or its roads of narration are not available in the database',
        feature_not_available: 'This feature is not yet available',
        hadith_copied: 'Hadith copied successfully',
        matn_copied: 'Matn copied successfully',
        url_copied: 'Url to share copied successfully',
        matn_info:
            'This feature is still under developement, The goal is to show the number of moutun of the ahadith in search result.',
        view_original_hadith: 'View Original Hadith',
        narrations: 'الروايات',
        view_narrations: 'View Narrations',
        font_size: 'Font Size',
        small_font: 'Small',
        normal_font: 'Normal',
        large_font: 'Large',
        verylarge_font: 'Very Large',
        tashkeel_nas: 'Tashkeel',
    },
    narratorBio: {
        _id: 'id',
        name: 'Name',
        month: 'Month',
        born_on: 'Date of Birth',
        date_of_death: 'Date of Death',
        city: 'City',
        narrator_bio: 'Narrator Bio',
        full_name: 'Full Name',
        extended_fullname: 'Extended Full Name',
        grade: 'Grade',
        nickname: 'Nickname',
        level: 'Level',
        origin: 'Origin',
        book_titles: 'Book Titles',
        lived_in: 'Lived in',
        died_in: 'Died in',
        died_on: 'Died on',
        commentary: 'Commentary',
        top_scholars: 'top_scholars',
        top_students: 'top_students',
    },
    references: {
        preview_disclaimer:
            'Book Preview feature is under development and may have some errors',
        bookName: 'Book Name',
        fullBookName: 'Full Book Name',
        author: 'Author',
        verifier: 'Verifier',
        publisher: 'Publisher',
        edition: 'Edition',
        numOfParts: 'Number Of Parts',
        edition_tooltip: 'This version is used in numbering',
        type: 'Type',
        filters: 'Filters',
        select_all: 'Select All',
        status: 'Status',
        progress: 'Progress',
        not_available: 'Unavailable',
        references_count: 'References Count',
        search_for_an_author: 'Search for an author',
        results_matching_search_criteria_1: '',
        results_matching_search_criteria_2: '',
        results_matching_search_criteria_gt10: '',
        results_matching_search_criteria_lt10: '',
        status_filter_header: 'Book Status',
        author_filter_header: '',
        book_count_label: '',
        clear_all: 'Clear All',
        apply: 'Apply',
        book_type_filter_header: '',
        search_in_references: 'Search in References',
        no_results_found: 'No Results Found',
        references_count_with_filters: 'References Count With Filters',
        book_perview_not_clickable:
            'This feature is not yet available for this book',
        quick_navigation: 'التنقل السريع',
    },
    '404': {
        message:
            'Page not found. Please use the navigation bar at the top to navigate to another page.',
    },
    bot: {
        message:
            "It looks like you're using a bot to crawl the site or download the data. Please contact us if incorrect or if you'd like to share the data",
    },
    offline: {
        message: 'There is no internet connection',
        retry: 'Try again',
    },
    privacy: {
        policy_title: 'Hadith Platform Privacy Policy',
        policy: 'tamayyuz center built the Hadith Platform app as a Free app. This SERVICE is provided by tamyyuz center at no cost and is intended for use as is. This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service. If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.',
        collection_and_use_title: 'Information Collection and Use',
        collection_and_use:
            'The app does use third party services that may collect information used to identify you. Link to privacy policy of third party service providers used by the app',
        log_data_title: 'Log Data',
        log_data:
            'We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.',
        cookies_title: 'Cookies',
        cookies_1:
            "Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.",
        cookies_2:
            'This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.',
        service_providers_title: 'Service Providers',
        service_providers:
            'We may employ third-party companies and individuals due to the following reasons:',
        service_providers_reasons: {
            1: 'To facilitate our Service;',
            2: 'To provide the Service on our behalf;',
            3: 'To perform Service-related services;',
            4: 'To assist us in analyzing how our Service is used.',
        },
        service_providers_2:
            'We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.',
        security_title: 'Security',
        security:
            'We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.',
        links_to_other_sites_title: 'Links to Other Sites',
        links_to_other_sites:
            'This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.',
        rights_title:
            'You have the right under this Privacy Policy, and by law:',
        rights: 'Request access or to Your Personal Data. The right to access, update or delete the information We have on You. You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.',
        contact_us_title: 'Contact Us',
        contact_us:
            'If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at support@tamayyuzcenter.com.',
    },
};
